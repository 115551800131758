import { Card, CardFooter, CardHeader, Divider, Link } from "@nextui-org/react";
import {
  ActiveElement,
  ChartEvent,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
  TooltipItem,
} from "chart.js";
import { motion } from "framer-motion";
import { useTheme } from "next-themes";
import { useEffect, useState } from "react";
import { Radar } from "react-chartjs-2";
import { FeatureTeacher, RadarGraphType, XMatch, SelectedPoint } from "../types/reportTypes";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const spring = {
  type: "spring",
  stiffness: 400,
  damping: 20,
};

export default function RadarGraph(radarGraph: RadarGraphType) {
  const [selectedPoint, setSelectedPoint] = useState<SelectedPoint>({ dataset: null, index: null });
  const [chartOptions, setChartOptions] = useState({});
  const { theme } = useTheme();

  useEffect(() => {
    const baseOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        r: {
          min: 0,
          max: 110,
          ticks: {
            display: true,
            stepSize: 10,
            callback: function (value: number) {
              return value === 10 ? 0 : value - 10;
            },
            showLabelBackdrop: true,
            backdropColor: theme === "dark" ? "black" : "white",
            color: theme === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.7)",
          },
          pointLabels: {
            font: {
              size: 12,
            },
          },
        },
      },
      plugins: {
        legend: {
          labels: {
            color: "rgba(0, 0, 0, 0.8)",
          },
        },
        tooltip: {
          callbacks: {
            title: (context: TooltipItem<"radar">[]) => {
              const index = context[0].dataIndex;
              const datasetIndex = context[0].datasetIndex;

              const title = getTitle(datasetIndex, index);

              const maxLineLength = 70;
              const titleLines = [];
              for (let i = 0; i < title.length; i += maxLineLength) {
                titleLines.push(title.substring(i, i + maxLineLength));
              }
              return titleLines;
            },
            label: (context: TooltipItem<"radar">) => {
              const adjustedValue = parseInt(context.formattedValue) - 10;
              return `Score: ${adjustedValue}`;
            },
          },
        },
      },

      onClick: (_event: ChartEvent, elements: ActiveElement[]) => {
        if (elements.length > 0) {
          const { datasetIndex, index } = elements[0];
          setSelectedPoint((prevPoint) =>
            prevPoint.dataset === datasetIndex && prevPoint.index === index
              ? { dataset: null, index: null }
              : { dataset: datasetIndex, index }
          );
        } else {
          setSelectedPoint({ dataset: null, index: null });
        }
      },
    };

    if (theme != undefined && theme == "dark") {
      setChartOptions({
        ...baseOptions,
        scales: {
          ...baseOptions.scales,
          r: {
            ...baseOptions.scales.r,
            grid: {
              color: "rgba(255, 255, 255, 0.2)",
            },
            angleLines: {
              color: "rgba(255, 255, 255, 0.2)",
            },
            pointLabels: {
              ...baseOptions.scales.r.pointLabels,
              color: "rgba(255, 255, 255, 0.7)",
            },
          },
        },
        plugins: {
          ...baseOptions.plugins,
          legend: {
            labels: {
              color: "rgba(255, 255, 255, 0.7)",
            },
          },
        },
      });
    } else {
      setChartOptions(baseOptions);
    }
  }, [theme]);

  const getTitle = (dataset: number | null, index: number | null): string => {
    if (dataset === null || index === null) return "";

    if (dataset === 0) {
      return radarGraph.summary.teacher_radar[index] === 0
        ? "No patent teaches this feature"
        : radarGraph.summary.feature_teachers[index.toString()]?.title || "";
    } else if (dataset === 1) {
      return radarGraph.summary.X_radar[index] === 0
        ? "No patent matches this feature"
        : radarGraph.summary.X_matches[index.toString()]?.title || "";
    }

    return "";
  };

  const getPatentLink = (dataset: number | null, index: number | null): string => {
    if (dataset === null || index === null) return "";

    if (
      (dataset === 0 && radarGraph.summary.teacher_radar[index] === 0) ||
      (dataset === 1 && radarGraph.summary.X_radar[index] === 0)
    ) {
      return "";
    }

    const data = dataset === 0 ? radarGraph.summary.feature_teachers : radarGraph.summary.X_matches;
    return (data[index.toString()] as FeatureTeacher | XMatch)?.url || "";
  };

  const getPatentNumber = (dataset: number | null, index: number | null): string => {
    if (dataset === null || index === null) return "";

    if (
      (dataset === 0 && radarGraph.summary.teacher_radar[index] === 0) ||
      (dataset === 1 && radarGraph.summary.X_radar[index] === 0)
    ) {
      return "";
    }

    const data = dataset === 0 ? radarGraph.summary.feature_teachers : radarGraph.summary.X_matches;
    return (data[index.toString()] as FeatureTeacher | XMatch)?.number || "";
  };

  const labels = Array.from(
    { length: radarGraph.summary.teacher_radar.length },
    (_, index) => `Feature ${index + 1}`
  );

  const data = {
    labels,
    datasets: [
      {
        label: "Feature Coverage",
        data: radarGraph.summary.teacher_radar.map((score: number) => score + 10),
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgb(255, 99, 132, 0.5)",
        pointBackgroundColor: "rgb(255, 99, 132, 0.5)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(255, 99, 132)",
      },
      {
        label: "Closest Full Match",
        data: radarGraph.summary.X_radar.map((score: number) => score + 10),
        backgroundColor: "rgba(100, 210, 203, 0.5)",
        borderColor: "rgb(100, 99, 132, 0.5)",
        pointBackgroundColor: "rgb(100, 99, 132, 0.5)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(100, 99, 132)",
      },
    ],
  };

  const paragraphVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <div className="flex flex-col lg:flex-row justify-around items-center max-w-[1100px] mx-auto p-5">
      <motion.div
        layout
        transition={spring}
        className="w-full max-w-[450px] min-w-[300px] aspect-square"
      >
        <Radar data={data} options={chartOptions} />
      </motion.div>
      {selectedPoint.index !== null && selectedPoint.dataset !== null && (
        <motion.div
          className="text-center max-w-sm mt-8 lg:mb-0"
          variants={paragraphVariants}
          initial="hidden"
          animate="visible"
        >
          <Card>
            <CardHeader className="justify-center">
              <p className="text-lg">{getTitle(selectedPoint.dataset, selectedPoint.index)}</p>
            </CardHeader>

            {getPatentLink(selectedPoint.dataset, selectedPoint.index) && <Divider />}
            <CardFooter className="justify-center">
              {getPatentLink(selectedPoint.dataset, selectedPoint.index) && (
                <Link
                  isExternal
                  showAnchorIcon
                  href={getPatentLink(selectedPoint.dataset, selectedPoint.index)}
                >
                  {getPatentNumber(selectedPoint.dataset, selectedPoint.index)}
                </Link>
              )}
            </CardFooter>
          </Card>
        </motion.div>
      )}
    </div>
  );
}
