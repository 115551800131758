//HEADER TYPES
export interface ReportHeaderProps {
  noveltyScore: number;
  titleMatter: string;
}

export interface FeatureListProps {
  primaryFeatures?: string[];
  secondaryFeatures?: string[];
}

//PRIOR ART TABLE SECTION TYPES
export interface ContentItem {
  paragraphNumber: string;
  similarity: number;
  detailForFeature: string;
}

export interface DetailItem {
  feature: string;
  content: ContentItem[];
}

export interface PriorArtEntry {
  coverage: number;
  patentNumber: string;
  patentLink?: string;
  familyNumber: string;
  title: string;
  inventors: string;
  abstract: string;
  match: string;
  detail: DetailItem[];
}

export interface PriorArtTableProps {
  priorArtTableData: PriorArtEntry[];
}

export interface AdditionalDetailsProps {
  patentDetail: PriorArtEntry;
}

//FEATURE SYNOPSIS TABLE SECTION TYPES

export interface FeatureDetail {
  feature: string;
  coverage: number;
  patentNumber: string;
  familyNumber: string;
  title: string;
  inventors: string[];
  patentLink: string;
  detail: string[];
}

export interface SynopsisDetailItem {
  similarity: number;
  content: string;
}

interface FeatureDetailV2 extends Omit<FeatureDetail, "detail"> {
  detail: SynopsisDetailItem[];
}

export function isFeatureDetailV2(data): data is FeatureDetailV2 {
  return data.detail.length > 0 && typeof data.detail[0] === "object";
}

export interface SecondaryFeatureTableProps {
  secondaryFeatureTable: (FeatureDetailV2 | FeatureDetail)[];
}

export interface PrimaryFeatureTableProps {
  primaryFeatureTable: (FeatureDetailV2 | FeatureDetail)[];
}

export interface AllFeatureTableProps
  extends PrimaryFeatureTableProps,
    SecondaryFeatureTableProps {}

export interface AdditionalFeaturesProps {
  featureDetail: FeatureDetailV2 | FeatureDetail;
}

type PriorArtTable = PriorArtEntry[];

//ArtSummaryTable Types
export interface PrimaryFeatureArtSummaryMatch {
  patentNumber: string;
  patentLink: string;
  matches: string[];
}

export interface PrimaryFeatureArtSummaryTableProps {
  header: string[];
  table: PrimaryFeatureArtSummaryMatch[];
}

export interface ReportData {
  noveltyScore: string;
  featureCoverage: number[];
  secondaryFeatureCoverage?: number[];
  primaryFeatures: string[];
  secondaryFeatures?: string[];
  priorArtTable: PriorArtTable;
  primaryFeatureTable: (FeatureDetail | FeatureDetailV2)[];
  secondaryFeatureTable?: (FeatureDetail | FeatureDetailV2)[];
  primaryFeatureArtSummaryTable?: PrimaryFeatureArtSummaryTableProps;
}

export interface SearchInputProps {
  inputData: {
    technology_area: string;
    invention_type: string;
    titleMatter: string;
    bigTextBox: string;
    primary: Array<Array<string>>;
    secondary: Array<string>;
    email: string;
  };
}
export interface FeatureTeacher {
  coverage: number;
  feature: string;
  number: string;
  title: string;
  inventors: string[];
  quote: string;
  url: string;
}

export interface RadarGraphType {
  summary: {
    feature_teachers: { [key: string]: FeatureTeacher };
    X_matches: { [key: string]: XMatch };
    X_radar: number[];
    teacher_radar: number[];
  };
}

export interface XMatch {
  feature: string;
  number: string;
  title: string;
  inventors: string[];
  quote: string;
  url: string;
}

export interface SelectedPoint {
  dataset: number | null;
  index: number | null;
}

export type AllReportData = {
  inputMetadata: SearchInputProps["inputData"]
  outputMetadata: {
    noveltyScore: number;
    summary:{ feature_teachers: { [key: string]: FeatureTeacher; }; X_matches: { [key: string]: XMatch; }; X_radar: number[]; teacher_radar: number[]; }
    primaryFeatureArtSummaryTable?: PrimaryFeatureArtSummaryTableProps;
    primaryFeatures: string[];
    secondaryFeatures: string[];
    priorArtTable: PriorArtEntry[];
    primaryFeatureTable: (FeatureDetailV2 | FeatureDetail)[];
    secondaryFeatureTable: (FeatureDetailV2 | FeatureDetail)[];
    priorArtReferences: string[];
  };
};