import { motion } from "framer-motion";
import { useState } from "react";
import { MdExpandLess, MdExpandMore } from "react-icons/md";

interface AbstractTextProps {
  content?: string | null;
  limit: number;
  patentLink: string | undefined;
}

export default function AbstractText({ content, limit, patentLink }: AbstractTextProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const enforceHttps = (url: string | undefined) => {
    if (url?.startsWith("https://")) return url;
    return url?.replace(/^http:\/\//i, "https://");
  };

  if (!content)
    return (
      <p className="p-4">
        Couldn't find the abstract. Full text available{" "}
        <a
          href={enforceHttps(patentLink)}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:text-blue-600"
        >
          here
        </a>
        .
      </p>
    );
  if (content.length <= limit) return <p>{content}</p>;

  const toggleExpanded = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const displayContent = isExpanded ? content : content.substring(0, limit) + "...";

  const collapsedHeight = 100;

  return (
    <div className="p-4 relative flex items-start justify-between">
      <p className="pr-10">
        <motion.span
          className="overflow-hidden"
          initial={false}
          animate={{ maxHeight: isExpanded ? 1000 : collapsedHeight }}
          transition={{ duration: 0.5, ease: "linear" }}
        >
          {displayContent}
        </motion.span>

        {content.length > limit && (
          <span
            onClick={toggleExpanded}
            className="absolute bottom-3.5 right-10 cursor-pointer w-6 h-6 rounded-full hover:bg-gray-200 flex items-center justify-center"
            role="button"
            aria-expanded={isExpanded}
          >
            {isExpanded ? <MdExpandLess size="15" /> : <MdExpandMore size="15" />}
          </span>
        )}
      </p>
    </div>
  );
}
